import Sticky from "react-stickynode";
import Navbar from "../containers/SecD3v/Navbar";
import { DrawerProvider } from "../common/contexts/DrawerContext";
import SEO from "../components/SEO";
import Banner from "../containers/SecD3v/HowGetStarted/Banner";
import Section1 from "../containers/SecD3v/HowGetStarted/Section1";
import Section2 from "../containers/SecD3v/HowGetStarted/Section2";
import Section3 from "../containers/SecD3v/HowGetStarted/Section3";
import Footer from "../containers/SecD3v/Footer";
import React from "react";
import RegisterForm from "../containers/SecD3v/RegisterForm";

const SecD3v = () => {
  return (
    <>
      <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
        <DrawerProvider>
          <Navbar />
        </DrawerProvider>
      </Sticky>
      <SEO title="Home" />
      <Banner />
      <Section1 />
      <Section2 />
      <Section3 />
      <RegisterForm />
      <Footer />
    </>
  );
};

export default SecD3v;
